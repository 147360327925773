#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}
button {
  margin: 5px;
}
.color {
  height: 200px;
  width: 200px;
  margin: 40px;
  width: 50%;
  margin: 20px auto;
  border-radius: 5px;
}
body {
  background-color: #242424;
}
button {
  color: #fff;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
  transition: outline 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 2px auto #646cff;
  transition: outline 0.25s;
}

.footer {
  position: fixed;
  bottom: 20px;
  width: 90%;
}

button.try {
  padding: 10px;
  border: none;
  /* outline: 2px solid green; */
}

button.try:focus-visible,
button.try:focus {
  outline: 2px solid #FFD700;
}

.try:hover {
  border-color: #FFD700;
}
